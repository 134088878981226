<template>
  <div id="app">
    <router-view/>
  </div>
  <div class="circle circle1"></div>
  <div class="circle circle2"></div>
  <div class="circle circle3"></div>
</template>

<script>
export default {
  data() {
    return {}
  },

  mounted() {

  },
  
  methods: {

  }
}
</script>

<style lang="scss">
body{
  user-select: none;
  --main-color: #25A3FF;
  --navi-color: #2976ff;
  
  --main-shadow: 0 0 20px 3px #00000020;
  --td-brand-color: var(--main-color);
}
.pageMargin{
  margin-left: 166px;
}
.circle{
  position: fixed;
  background: linear-gradient(270deg, #68C0FF, #c987ff);
  border-radius: 100%;
  filter: blur(5px);
  z-index: -10;
  box-shadow: 0 0 20px 5px #00000020;
  &.circle1 {
    left: -120px;
    top: -80px;
    height: 600px;
    width: 600px;
    animation: move 12s linear infinite;
  }
  &.circle2 {
    right: -400px;
    bottom: -120px;
    height: 800px;
    width: 800px;
    animation: move 16s linear infinite;
  }
  &.circle3 {
    left: 30%;
    bottom: -80px;
    height: 400px;
    width: 400px;
    animation: move 8s linear infinite;
  }
}
@keyframes move {
  0% {
    transform: rotate(0deg) translateX(0) translateY(0);
  } 25% {
    transform: rotate(180deg) translateX(5%) translateY(-5%);
  } 50% {
    transform: rotate(360deg) translateX(0%) translateY(-10%);
  } 75% {
    transform: rotate(540deg) translateX(-5%) translateY(-5%);
  } 100% {
    transform: rotate(0deg) translateX(0) translateY(0);
  }
}

.t_c{
  text-align: center;
}
.flex{
  display: flex;
}
.j_b{
  justify-content: space-between;
}
.j_c{
  justify-content: center;
}
.j_e{
  justify-content: space-evenly;
}
.a_c{
  align-items: center;
}
.f_c{
  flex-direction: column;
}
.m_left{
  margin-left: auto;
}
.gap{
  gap: 12px;
}
.bold{
  font-weight: 600;
}

.b_b{
  padding: 6px 12px;
  border-bottom: 1px solid #eeeeee;
  font-size: 14px;
}

.tag{
  font-weight: 500;
  padding: 3px 6px;
  border-radius: 6px;
  transition: 300ms;
  font-size: 10px;
  line-height: 1.4em;
  &.success{
    color: #00bd00;
    border: 1px solid #00bd00;
  }
  &.primary{
    color: #25A3FF;
    border: 1px solid #25A3FF;
  }
  &.warn{
    color: #d57011;
    border: 1px solid #d57011;
  }
  &.danger{
    color: #d51111;
    border: 1px solid #d51111;
  }
  &.default{
    color: #808080;
    border: 1px solid #808080;
  }
}

.ui-checkbox {
  --primary-color: #1677ff;
  --secondary-color: #fff;
  --primary-hover-color: #4096ff;
  /* checkbox */
  --checkbox-diameter: 18px;
  --checkbox-border-radius: 5px;
  --checkbox-border-color: #d9d9d9;
  --checkbox-border-width: 1px;
  --checkbox-border-style: solid;
  /* checkmark */
  --checkmark-size: 1;

  &,
  * {
    box-sizing: border-box;
  }
  
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: var(--checkbox-diameter);
  height: var(--checkbox-diameter);
  border-radius: var(--checkbox-border-radius);
  background: var(--secondary-color);
  border: var(--checkbox-border-width) var(--checkbox-border-style) var(--checkbox-border-color);
  transition: all 0.3s;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
    border-radius: inherit;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }

  &::before {
    top: 40%;
    left: 50%;
    content: "";
    position: absolute;
    width: 4px;
    height: 7px;
    border-right: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
    transform: translate(-50%, -50%) rotate(45deg) scale(0);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  }

  /* actions */
  &:hover {
  cursor: pointer;
    border-color: var(--primary-color);
  }

  &:checked {
    background: var(--primary-color);
    border-color: transparent;

    &::before {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    }
  }

  &:active:not(:checked)::after {
    transition: none;
    box-shadow: none;
    opacity: 1;
  }
}
.pagination{
  margin-top: 18px;
}
</style>