<template>
  <div class="empty">
    <img src="../assets/img/该页面删除或不存在.svg" class="image">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'emptyComp',
  props: {
    text: {
      type: String,
      default: '内容为空'
    }
  }
}
</script>

<style>
.empty{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image{
    max-height: 60%;
    max-width: 60%;
    margin-top: 0px;
  }
  .text{
    margin-top: 36px;
    font-size: 14px;
    color: #666666;
  }
}
</style>