import { createApp } from 'vue';
import App from './App.vue';

import router from './router/index';
import axios from 'axios';

const app = createApp(App);

import frameComp from './layout/frameComp.vue';
import emptyComp from './components/emptyComp.vue';
import { DatePicker, Dialog, DialogPlugin, Message, MessagePlugin, Pagination, TimeRangePicker } from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';

init()
app.mount('#app');

function init() {
  useComponet();
  checkLog();
  app.config.globalProperties.$axios = axios;
  const hostname = window.location.hostname;
  if(hostname.includes('localhost')) {
    app.config.globalProperties.$api = 'http://localhost:3000/api';
    app.config.globalProperties.$file = 'http://localhost:3000/file';
  } else {
    app.config.globalProperties.$api = `https://${hostname}/api`;
    app.config.globalProperties.$file = `https://${hostname}/file`;
  }
  app.config.globalProperties.$screenWidth = window.innerWidth;
}

function useComponet() {
  app.component('frameComp', frameComp);
  app.component('emptyComp', emptyComp);
  
  app.use(router);
  app.use(Message);
  app.use(Pagination);
  app.use(Dialog);
  app.use(DatePicker);
  app.use(TimeRangePicker);

  app.use(MessagePlugin);
  app.use(DialogPlugin);
  // app.use(Icon);
}

function checkLog() {
  const logTime = parseInt(localStorage.getItem('logTime') || 0);
  if(!logTime) return;
  const now = Date.now();
  if(now > logTime + 10 * 60 * 60 * 1000) {
    localStorage.removeItem('user');
    localStorage.removeItem('header');
  }
  const user = JSON.parse(localStorage.getItem('user'));
  if(!user || !user._id) router.replace('/');
}