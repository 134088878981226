import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/home'
  }, {
    path: '/home',
    name: 'home',
    component: () => import('../pages/homePage.vue')
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../pages/loginPage.vue')
  }, {
    path: '/room',
    name: 'room',
    component: () => import('../pages/roomPage.vue')
  }, {
    path: '/essay',
    name: 'essay',
    component: () => import('../pages/essayPage.vue')
  }, {
    path: '/active',
    name: 'active',
    component: () => import('../pages/activePage.vue')
  // }, {
  //   path: '/publish',
  //   name: 'publish',
  //   component: () => import('../pages/publishPage.vue')
  }, {
    path: '/organization',
    name: 'organization',
    component: () => import('../pages/orgPage.vue')
  }, {
    path: '/admin/active',
    name: 'activeAdmin',
    component: () => import('../pages/admin/activeAdmin.vue')
  }, {
    path: '/admin/essay',
    name: 'essayAdmin',
    component: () => import('../pages/admin/essayAdmin.vue')
  }, {
    path: '/admin/organization',
    name: 'organizationAdmin',
    component: () => import('../pages/admin/orgAdmin.vue')
  }, {
    path: '/admin/room',
    name: 'roomAdmin',
    component: () => import('../pages/admin/roomAdmin.vue')
  }, {
    path: '/admin/user',
    name: 'userAdmin',
    component: () => import('../pages/admin/userAdmin.vue')
  }, {
    path: '/admin/notice',
    name: 'noticeAdmin',
    component: () => import('../pages/admin/noticeAdmin.vue')
  }, {
    path: '/admin/member',
    name: 'memberAdmin',
    component: () => import('../pages/admin/memberAdmin.vue')
  }, {
    path: '/admin/appoint',
    name: 'appointAdmin',
    component: () => import('../pages/admin/appointAdmin.vue')
  }, {
    path: '/admin/login',
    name: 'loginAdmin',
    component: () => import('../pages/admin/loginAdmin.vue')
  },

  {
    path: '/chatai',
    name: 'chatai',
    component: () => import('../pages/aiPage.vue')
  }
];

var router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;